import React from "react"
import Layout from "../components/layout"

const About = () => (
  <Layout>
    <h1>About</h1>
    <p>This page is built from the ground up with GatsbyJS.</p>
  </Layout>
)

export default About
